import NyukinkeshikomiIndex from "./NyukinkeshikomiIndex";
import * as domutils from '../domutils/index';

/**
 * 入金消込請求分レコードリロード処理
 */
class ReloadNyukinKeshikomiRecord {
    nyukinkeshikomi: NyukinkeshikomiIndex;
    reloadNyukinKeshikomiRecordElement: HTMLElement;

    constructor(nyukinkeshikomi: NyukinkeshikomiIndex, reloadNyukinKeshikomiRecordElement: HTMLElement) {
        this.nyukinkeshikomi = nyukinkeshikomi;
        this.reloadNyukinKeshikomiRecordElement = reloadNyukinKeshikomiRecordElement;

        this.nyukinkeshikomi.setHandleButtons(this.reloadNyukinKeshikomiRecordElement);

        this.reloadNyukinKeshikomiRecordElement.addEventListener("reload", (event) => this.handleReload(event));
    }

    private handleReload(event: Event) {
        let reloadUrl = domutils.getDatasetValue(this.reloadNyukinKeshikomiRecordElement, "reloadUrl");

        fetch(reloadUrl, { method: "GET" })
        .then((response) => response.text())
        .then((text) => this.reload(text));
    }

    private reload(text: string) {
        // レスポンスを DOM に変換
        let tbody = document.createElement("tbody");
        tbody.innerHTML = text;

        // 設定済みのイベントを消さないように innerHTML を使ってリロード
        this.reloadNyukinKeshikomiRecordElement.innerHTML = domutils.requireObject(tbody.firstElementChild?.innerHTML);

        // 入金明細の二行目以降を後ろに追加
        let current: Element = this.reloadNyukinKeshikomiRecordElement;
        let next = tbody.firstElementChild?.nextElementSibling;
        while (next) {
            let w = next.nextElementSibling;
            current.insertAdjacentElement("afterend", next);
            current = next;
            next = w;
        }

        let seikyushoId = this.reloadNyukinKeshikomiRecordElement.dataset["seikyushoId"];
        let uriageId = this.reloadNyukinKeshikomiRecordElement.dataset["uriageId"];

        // 残った入金明細の二行目以降を削除
        if (seikyushoId !== undefined) {
            while (current.nextElementSibling instanceof HTMLElement) {
                if (current.nextElementSibling.dataset["seikyushoId"] !== this.reloadNyukinKeshikomiRecordElement.dataset["seikyushoId"]) {
                    break;
                }

                current.nextElementSibling.remove();
            }
        }

        if (uriageId !== undefined) {
            while (current.nextElementSibling instanceof HTMLElement) {
                if (current.nextElementSibling.dataset["uriageId"] !== this.reloadNyukinKeshikomiRecordElement.dataset["uriageId"]) {
                    break;
                }

                current.nextElementSibling.remove();
            }
        }

        // イベント設定
        this.nyukinkeshikomi.setHandleButtons(this.reloadNyukinKeshikomiRecordElement);
    }
}

export default ReloadNyukinKeshikomiRecord;
