import * as domutils from '../domutils/index';

const bootstrap = require('bootstrap');

class ConfirmDialog {

    element: Element;
    confirmedEventListener: EventListenerOrEventListenerObject;
    message: string;

    /**
     * 確認ダイアログをセットアップする。
     *
     * 確認ダイアログの確認メッセージと確認ボタンをクリックしたときに実行するイベントリスナーを指定する。
     *
     * @param {*} message 確認メッセージ
     * @param {*} confirmedEventListener 確認ボタンをクリックしたときに実行するイベントリスナー
     *
     * @returns #confirmDialog 要素
     */

    constructor(element: Element, message: string, confirmedEventListener: EventListenerOrEventListenerObject) {
        this.element = element;
        this.message = message;
        this.confirmedEventListener = confirmedEventListener;

        // 指定された confirmed イベントリスナーを追加する。
        this.element.addEventListener("confirmed", confirmedEventListener);
    }

    /**
     * 確認ダイアログの bootstrap モーダルインスタンスを取得する。
     *
     * #confirmDialog 要素に関連づいていればそれを返し、
     * 関連づいていなければインスタンスを生成して返す。
     *
     * @returns モーダルインスタンス
     */
    private getModal(): any {
		let modal = bootstrap.Modal.getInstance(this.element);
		if (modal == null) {
			modal = new bootstrap.Modal(this.element);

            // 「はい」をクリックされたら confirmed イベントを発生させる。
            this.element.querySelectorAll("[data-es-action=confirmed]").forEach((element) => {
                element.addEventListener("click", (event) => this.handleConfirmedClick(event));
            });
        }
		return modal;
	}

    private getModalBody(): Element {
        return domutils.requireObject(this.element.querySelector(".modal-body"));
    }

    private handleConfirmedClick(event: Event) {
        let confirmedEvent = new CustomEvent('confirmed');
        this.element.dispatchEvent(confirmedEvent);
    }

    show() {
        this.getModalBody().textContent = this.message;

        // ダイアログを表示する。
        this.getModal().show();
    }

}

export default ConfirmDialog;