export function setSendForm(element: HTMLElement, beforeSendForm?: (formData: FormData) => void, afterLoad?: (response: any) => void) {

    if (!confirm(element)) {
        return;
    }

    let form: HTMLFormElement = (element as any).form;
    if (form == null) {
        console.warn("ajaxutil#setSendForm(): form == null");
        return;
    }

    const request = new XMLHttpRequest();
    request.open(form.method, form.action);
    request.addEventListener('load', (ev) => {
        let response = JSON.parse(request.response);

        if (afterLoad != undefined) {
            afterLoad(response);
        }
    });

    timeout(element, () => {
        let formData = new FormData(form);

        if (beforeSendForm != undefined) {
            beforeSendForm(formData);
        }

        request.send(formData);
    });
}

export function setReload(element: Element, afterLoad?: () => void) {
    if (!(element instanceof HTMLElement)) {
        console.warn("ajaxutil#setReload(): !(element instanceof HTMLElement)");
        return;
    }

    element.addEventListener("reload", (event) => {
        if (!(event instanceof CustomEvent)) {
            console.warn("ajaxutil#setReload(): !(event instanceof CustomEvent)");
            return;
        }

        let reloadUrl = element.dataset.reloadUrl;
        if (reloadUrl == undefined) {
            console.warn("ajaxutil#setReload(): reloadUrl == undefined");
            return;
        }

        while (true) {
            let s = reloadUrl.indexOf("{") + 1;
            let e = reloadUrl.indexOf("}", s) - 1;

            let len = e - s + 1;
            if (len < 1) {
                break;
            }

            let key = reloadUrl.substring(s, s + len);

            let value;

            if (event.detail.hasOwnProperty(key)) {
                value = event.detail[key];
            } else {
                value = key;
            }

            reloadUrl = reloadUrl.replace('{' + key + '}', value);
        }

        const request = new XMLHttpRequest();
        request.open("GET", reloadUrl);
        request.addEventListener('load', () => {
            while (element.firstChild != null) {
                element.removeChild(element.firstChild);
            }

            element.innerHTML = request.response;

            if (afterLoad != undefined) {
                afterLoad();
            }
        });

        request.send();
    });
}

function confirm(element: HTMLElement) {
    if (element.dataset.confirm == undefined) {
        return true;
    }

    return window.confirm(element.dataset.confirm);
}

function timeout(element: HTMLElement, callback: () => void) {
    if (element.dataset.timeout == undefined) {
        callback();
        return;
    }

    let timeout = Number(element.dataset.timeout);

    // TODO setTimeout() だとタイミング次第。
    window.setTimeout(callback, timeout);

    console.info("ajaxutil.timeout(): window.setTimeout(callback, " + timeout + ")");
}
