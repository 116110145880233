

// 使用禁止
export function onEvent(element: Document|Element, selectors: string, event: string, callback: (element: Element, event: Event) => void): void {
    const elements = element.querySelectorAll(selectors);

    for (let i = 0; i < elements.length; i++) {
        const e = elements.item(i);

        e.addEventListener(event, ev => {
            if (callback !== undefined) {
                callback(e, ev);
            }
        });
    }
}

export function findAll(element: Document|Element|undefined, selectors: string, callback?: (element: HTMLElement) => void): NodeListOf<Element>|undefined {
    if (element == undefined) {
        console.warn("domutil#findAll(): element == undefined.");
        return;
    }

    const elements =  element.querySelectorAll(selectors);

    for (let i = 0;  i < elements.length; i++) {
        const e =  elements.item(i);
        if (e  instanceof HTMLElement) {
            if  (callback != undefined) {
                 callback(e);
             }
        }
    }

    return elements;
}

export function findFirst(element: Document|Element|undefined, selectors: string, callback?: (element: HTMLElement) => void): HTMLElement|undefined {
    if (element == undefined) {
        console.warn("domutil#findFirst(): element == undefined.");
        return;
    }

    const elements = element.querySelectorAll(selectors);

    for (let i = 0; i < elements.length; i++) {
        const e = elements.item(i);
        if (e instanceof HTMLElement) {
            if (callback != undefined) {
                callback(e);
            }
            return e;
        }
    }
}

export function getValue(element: Document|Element|undefined, selectors: string): string|undefined {
    if (element == undefined) {
        console.warn("domutil#getValue(): element == undefined.");
        return;
    }

    const elements = element.querySelectorAll(selectors);

    for (let i = 0; i < elements.length; i++) {
        const e = elements.item(i);
        if (e instanceof HTMLInputElement) {
            return e.value;
        }
    }
}

export function getNumber(element: Document|Element|undefined, selectors: string): number|undefined {
    if (element == undefined) {
        console.warn("domutil#getNumber(): element == undefined.");
        return;
    }

    const elements = element.querySelectorAll(selectors);

    for (let i = 0; i < elements.length; i++) {
        const e = elements.item(i);
        if (e instanceof HTMLInputElement) {
            return Number(e.value);
        }
    }
}

/**
 * 指定したエレメントに値を設定する。
 *
 * @param {*} element 基準となる element
 * @param {*} selectors セレクター
 * @param {*} value 設定する値
 * @param {*} dispatchEvent false イベントを発生しない、false 以外 指定したイベントを発生させる。"click" など。
 */
export function setValue(element: Document|Element|undefined, selectors: string, value: any, dispatchEvent?: string) {
    if (element == undefined) {
        console.warn("domutil#setValue(): element == undefined.");
        return;
    }

    const elements = element.querySelectorAll(selectors);

    for (let i = 0; i < elements.length; i += 1) {
        const e = elements.item(i);
        if (e instanceof HTMLInputElement) {
            e.value = value;
        } else if (e instanceof HTMLSelectElement) {
            if (value !== "") {
                findFirst(e, "[value="+ value + "]", e => {
                    if (e instanceof HTMLOptionElement) {
                        e.selected = true;
                    }
                });
            }
        } else {
            e.innerHTML = value;
        }
        if (dispatchEvent) {
            const event = new CustomEvent(dispatchEvent);
            e.dispatchEvent(event);
        }
    }
}

export function setHref(element: Document|Element|undefined, selectors: string, value: any, dispatchEvent?: string) {
    if (element == undefined) {
        console.warn("domutil#setValue(): element == undefined.");
        return;
    }

    const elements = element.querySelectorAll(selectors);

    for (let i = 0; i < elements.length; i += 1) {
        const e = elements.item(i);
        if (e instanceof HTMLAnchorElement) {
            e.href = value;

            if (dispatchEvent) {
                const event = new CustomEvent(dispatchEvent);
                e.dispatchEvent(event);
            }
        }
    }
}

export function clearValue(element: Document|Element|undefined, selectors: string, dispatchEvent?: string) {

    if (element == undefined) {
        console.warn("domutil#clearValue(): element == undefined.");
        return;
    }

    const elements = element.querySelectorAll(selectors);

    for (let i = 0; i < elements.length; i += 1) {
        const e = elements.item(i);
        if (e instanceof HTMLInputElement) {
            e.value = "";
        } else if (e instanceof HTMLSelectElement) {
            e.selectedIndex = 0;
        } else {
            e.innerHTML = "";
        }
        if (dispatchEvent) {
            const event = new CustomEvent(dispatchEvent);
            e.dispatchEvent(event);
        }
    }

}

export const displayNone = (e: HTMLElement) => e.classList.add("d-none");

export const display = (e: HTMLElement) => e.classList.remove("d-none");
