import * as domutils from '../domutils/index';

export class Times {

    element: Element;

    constructor(element: Element) {
        this.element = element;
        element.addEventListener("times", (event) => this.handleTimes(event));
        this.timesXElement().addEventListener("change", (event) => this.handleChange(event));
        this.timesYElement().addEventListener("change", (event) => this.handleChange(event));
    }

    private dataTimesX() {
        return domutils.getDatasetValue(this.element, "timesX");
    }

    private dataTimesY() {
        return domutils.getDatasetValue(this.element, "timesY");
    }

    private handleTimes(event: Event): any {
        let x = this.timesXElement();
        let y = this.timesYElement();

        domutils.setValue(this.element, Number(x.value) * Number(y.value));
    }

    private handleChange(event: Event): any {
        const timesEvent = new CustomEvent("times");
        this.element.dispatchEvent(timesEvent);
    }

    private timesXElement(): HTMLInputElement {
        return domutils.findFirst(document, this.dataTimesX());
    }

    private timesYElement(): HTMLInputElement {
        return domutils.findFirst(document, this.dataTimesY());
    }
}