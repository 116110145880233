import * as ajaxutil from '../ajaxutil';
import * as domutils from '../domutils/index';
import * as action from '../action/index';

import EasyInputDate from '../domutils/easy-input-date';
import ReloadNyukinKeshikomiRecord from './ReloadNyukinKeshikomiRecord';
import NyukinKeshikomiDialog from './NyukinKeshikomiDialog';
import UriageKeshikomiDialog from './UriageKeshikomiDialog';
import ConfirmSubmitButton from '../action/ConfirmSubmitButton';

class NyukinkeshikomiIndex {

    nyukinkeshikomiIndexElement: HTMLElement;                                     // 基準要素
    nyukinKeshikomiDialog: NyukinKeshikomiDialog;   // 入金額編集ダイアログ
    editUriageDialog: UriageKeshikomiDialog;

    static initComponent() {
        document.querySelectorAll<HTMLElement>("#nyukinKeshikomiIndex").forEach((element) => {
            new NyukinkeshikomiIndex(element);
        });
    }

    constructor(bodyElement: HTMLElement) {
        // 入金消込一覧の body 部を基準要素として取得する。
        this.nyukinkeshikomiIndexElement = bodyElement;

        // 入金額編集ダイアログを初期化しておく。
        this.nyukinKeshikomiDialog = new NyukinKeshikomiDialog();

        this.editUriageDialog = new UriageKeshikomiDialog();

        ConfirmSubmitButton.initComponent(this.nyukinkeshikomiIndexElement);

        // 入金消込一覧の各行の処理を設定する。
        this.nyukinkeshikomiIndexElement.querySelectorAll<HTMLElement>("[data-action=ReloadNyukinKeshikomiRecord]").forEach((element) => new ReloadNyukinKeshikomiRecord(this, element));

        this.nyukinkeshikomiIndexElement.querySelectorAll<HTMLElement>("[data-action~=reload-summary]").forEach((element) => ajaxutil.setReload(element));
        this.nyukinkeshikomiIndexElement.querySelectorAll<HTMLElement>("[data-action~=reload-memory]").forEach((element) => ajaxutil.setReload(element));

        // 入金ボタン
        this.nyukinkeshikomiIndexElement.querySelectorAll<HTMLElement>("[data-action~=send-reset]").forEach((element) => this.handleSendReset(element));
    }

    /**
     * 入金消込一覧の各行の処理を設定する。
     */
    private handleReloadNyukinKeshikomiRecord(element: HTMLElement) {
        // 各ボタンの処理を設定する。
        this.setHandleButtons(element);

        // リロードしたときに各ボタンの処理を設定する。
        ajaxutil.setReload(element, () => this.setHandleButtons(element));
    }

    /**
     * 各ボタンの処理を設定する。
     */
    public setHandleButtons(element: HTMLElement) {
        // 入金日
        element.querySelectorAll<HTMLInputElement>("input[type=date]").forEach((element) => new EasyInputDate(element));

        // 編集ボタン
        element.querySelectorAll<HTMLElement>("[data-action~=show-nyukin-keshikomi-dialog]").forEach((element) => this.handleShowNyukinKeshikomiDialog(element));
        // 編集ボタン
        element.querySelectorAll<HTMLElement>("[data-action~=show-uriage-keshikomi-dialog]").forEach((element) => this.handleShowEditUriageDialog(element));

        // 入金ボタン
        element.querySelectorAll<HTMLElement>("[data-action~=send-nyukin]").forEach((element) => this.handleSendNyukin(element));

        // 取消ボタン
        element.querySelectorAll<HTMLElement>("[data-action~=send-torikeshi]").forEach((element) => this.handleSendTorikeshi(element));
    }

    /**
     * 編集ボタンの処理
     */
    private handleShowNyukinKeshikomiDialog(element: HTMLElement) {
        element.addEventListener("click", (ev) => {
            const seikyushoId = domutils.getDatasetValue(element, "seikyushoId");
            const reloadTarget = domutils.requireObject(this.nyukinkeshikomiIndexElement.querySelector<HTMLElement>("[data-action=ReloadNyukinKeshikomiRecord]:has(#" + element.id + ")"));
            this.nyukinKeshikomiDialog.show(Number(seikyushoId), reloadTarget);
        });
    }

    /**
     * 編集ボタンの処理
     */
    private handleShowEditUriageDialog(element: HTMLElement) {
        element.addEventListener("click", (ev) => {
            const uriageId = domutils.getDatasetValue(element, "uriageId");
            const reloadTarget = domutils.requireObject(this.nyukinkeshikomiIndexElement.querySelector<HTMLElement>("[data-action=ReloadNyukinKeshikomiRecord]:has(#" + element.id + ")"));
            this.editUriageDialog.show(Number(uriageId), reloadTarget);
        });
    }


    /**
     * 入金ボタンの処理
     */
    private handleSendNyukin(element: HTMLElement) {

        // 入力されている入金日と入金額で請求書を更新する。
        // 入金額が入力されていない場合は請求額を入金額とする。
        //
        // この処理はリクエストを送信する前に呼び出される。
        let beforeSendForm = (formData: FormData) => {

            let tr = domutils.requireObject(element.parentElement?.parentElement?.parentElement);

            // 入力されている入金日を取得する。
            // 入力されていないときは画面上にある入金日を設定する。
            let nyukinbiElement = domutils.requireObject(tr.querySelector("#nyukinbi" + element.dataset.index));
            let nyukinbi = domutils.getValue(nyukinbiElement);
            if (nyukinbi == "") {
                let defaultNyukinbiElement = domutils.requireObject(document.querySelector("#defaultNyukinbi"));
                nyukinbi = domutils.getValue(defaultNyukinbiElement);
            }

            formData.set("nyukinbi", nyukinbi);

            let bikoElement = domutils.requireObject(tr.querySelector("#biko" + element.dataset.index));
            let biko = domutils.getValue(bikoElement);
            if (biko == "") {
                let defaultBikoElement = domutils.requireObject(document.querySelector("#defaultBiko"));
                biko = domutils.getValue(defaultBikoElement);
            }

            formData.set("biko", biko);

            let nyukinhohoElement = domutils.requireObject(tr.querySelector("#nyukinhoho" + element.dataset.index));
            let nyukinhoho = domutils.getValue(nyukinhohoElement);
            if (nyukinhoho == "") {
                let defaultNyukinhohoElement = domutils.requireObject(document.querySelector("#defaultNyukinhoho"));
                nyukinhoho = domutils.getValue(defaultNyukinhohoElement);
            }

            formData.set("nyukinhoho", nyukinhoho);

            // 入力されている入金額を取得する。
            // 入力されていないときは element にあらかじめ設定してある請求額を設定する。
            let nyukingakuElement = domutils.requireObject(document.querySelector("#nyukingaku" + element.dataset.index));
            let nyukingaku = domutils.getNumber(nyukingakuElement);
            if (nyukingaku == 0) {
                let tesuryoElement = document.querySelector("#tesuryo" + element.dataset.index);
                if (tesuryoElement !== null) {
                    let tesuryo = domutils.getNumber(tesuryoElement);

                    nyukingaku = Math.trunc(Number(element.dataset.seikyugaku).valueOf() - tesuryo.valueOf());
                } else {
                    nyukingaku = Number(element.dataset.seikyugaku).valueOf();
                }
            }

            // フォームデータを設定する。
            // その他の項目は HTML でフォームと関連付けられている
            formData.set("nyukingaku", nyukingaku.toString());
        }

        // 入金消込一覧をリロードする。
        let afterLoad = (response: any) => {
            let seikyushoIdList = element.dataset["seikyushoIdList"];

            if (seikyushoIdList !== undefined) {
                JSON.parse(domutils.getDatasetValue(element, "seikyushoIdList")).forEach((seikyushoId: any) => {
                    let reloadTarget = document.querySelector("[data-action=ReloadNyukinKeshikomiRecord][data-seikyusho-id='" + seikyushoId + "']");
                    if (reloadTarget == null) {
                        return;
                    }

                    const reloadEvent = new CustomEvent("reload");
                    reloadTarget.dispatchEvent(reloadEvent);
                });
            } else {
                let reloadTarget = domutils.findFirst(document, "[data-action=ReloadNyukinKeshikomiRecord]:has(#" + element.id + ")");

                const reloadEvent = new CustomEvent("reload");
                reloadTarget.dispatchEvent(reloadEvent);
            }

            this.nyukinkeshikomiIndexElement.querySelectorAll<HTMLElement>("#summary").forEach((element) => {
                const reloadEvent = new CustomEvent("reload");
                element.dispatchEvent(reloadEvent);
            });

            this.nyukinkeshikomiIndexElement.querySelectorAll<HTMLElement>("#memory").forEach((element) => {
                const reloadEvent = new CustomEvent("reload");
                element.dispatchEvent(reloadEvent);
            });
        }

        element.addEventListener("click", (ev) => {
            ajaxutil.setSendForm(element, beforeSendForm, afterLoad)
        });
    }

    private handleSendReset(element: HTMLElement) {
        let beforeSendForm = (formData: FormData) => {
        }
        let afterLoad = (response: any) => {
            this.nyukinkeshikomiIndexElement.querySelectorAll<HTMLElement>("#memory").forEach((element) => {
                const reloadEvent = new CustomEvent("reload");
                element.dispatchEvent(reloadEvent);
            });
        }
        element.addEventListener("click", (ev) => {
            ajaxutil.setSendForm(element, beforeSendForm, afterLoad)
        });
    }

    /**
     * 取消ボタンの処理
     */
    private handleSendTorikeshi(element: HTMLElement) {
        // 入金日と入金額をクリアする。
        // この処理はリクエストを送信する前に呼び出される。
        let beforeSendForm = (formData: FormData) => {
            // フォームデータを設定する。取消なので入金日と入金額と受領日をクリアする。
            // 更新処理側で入金データがリセットされる。
            formData.append("nyukinbi", "");
            formData.append("nyukingaku", "0");
            formData.append("tesuryo", "0");
         }

        // 入金消込一覧をリロードする。
        let afterLoadSeikyusho = (response: any) => {
            let seikyushoIdList = element.dataset["seikyushoIdList"];

            if (seikyushoIdList !== undefined) {
                JSON.parse(domutils.getDatasetValue(element, "seikyushoIdList")).forEach((seikyushoId: any) => {
                    let reloadTarget = document.querySelector("[data-action=ReloadNyukinKeshikomiRecord][data-seikyusho-id='" + seikyushoId + "']");
                    if (reloadTarget == null) {
                        return;
                    }

                    const reloadEvent = new CustomEvent("reload");
                    reloadTarget.dispatchEvent(reloadEvent);
                });
            } else {
                let reloadTarget = domutils.findFirst(document, "[data-action=ReloadNyukinKeshikomiRecord]:has(#" + element.id + ")");

                const reloadEvent = new CustomEvent("reload");
                reloadTarget.dispatchEvent(reloadEvent);
            }

            this.nyukinkeshikomiIndexElement.querySelectorAll<HTMLElement>("#summary").forEach((element) => {
                const reloadEvent = new CustomEvent("reload");
                element.dispatchEvent(reloadEvent);
            });

            this.nyukinkeshikomiIndexElement.querySelectorAll<HTMLElement>("#memory").forEach((element) => {
                const reloadEvent = new CustomEvent("reload");
                element.dispatchEvent(reloadEvent);
            });
        }

        element.addEventListener("click", (ev) => {
            ajaxutil.setSendForm(element, beforeSendForm, afterLoadSeikyusho)
        });
    }

}

export default NyukinkeshikomiIndex;

window.addEventListener('DOMContentLoaded', (event) => {
    NyukinkeshikomiIndex.initComponent();
});
