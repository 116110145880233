/**
 * インクリメンタルサーチ実装
 *
 * 使い方:
 * import * as isearch from './isearch.js';
 *
 * let isearchObj = new isearch.NewObject(selector, apiPath, valueAttr, textAttr);
 * isearchObj.valueElement.addEventListener("isearch-change", (event) => {
 *   // 決定時処理を書く。
 * });
 *
 * @param {*} selector  コードや ID などの値項目を指す selector または element
 * @param {*} apiPath   API のパス。json を返す。同一ドメイン。
 * @param {*} valueAttr 値属性名。json のキー。
 * @param {*} textAttr  テキスト属性名。json のキー。
 */
export function NewObject(selector, apiPath, valueAttr, textAttr) {

  if (typeof(selector) == 'string') {
    this.valueElement = document.querySelector(selector);
  } else {
    this.valueElement = selector;
  }

  this.initValue = this.valueElement.value;  // 値を初期状態に戻すときに使用する。

  this.apiPath = apiPath;

  this.valueAttr = valueAttr;
  this.textAttr = textAttr;

  let dropdownElement = query_parent(this.valueElement, ".dropdown");

  this.dropdownMenuElement = dropdownElement.querySelector(".dropdown-menu");
  this.clearButtonElement = dropdownElement.querySelector("[data-isearch-clear]");

  this.valueElement.addEventListener("keydown", (event) => {
    if (event.key == "Escape") {
      event.preventDefault();
      this.escapeKeydown(event);
      return;
    }

    if (event.key == "ArrowUp") {
      event.preventDefault();
      this.arrowUpKeydown(event);
      return;
    }

    if (event.key == "ArrowDown") {
      event.preventDefault();
      this.arrowDownKeydown(event);
      return;
    }

    if (event.key == "Enter") {
      event.preventDefault();
      this.enterKeydown(event);
      return;
    }

  });

  // this.valueElement.addEventListener("change", (event) => {
    // event.preventDefault();
    // this.changeValueElement(event);
    // TODO setTimeout() だとタイミング次第。
    // let obj = this;
    // window.setTimeout(() => {
    //   console.log("change");
    //   obj.changeValueElement(event);
    // }, 200);
  // });

  this.valueElement.addEventListener("input", (event) => {
    event.preventDefault();
    this.inputValueElement(event);
  });

  this.valueElement.addEventListener("blur", (event) => {
    event.preventDefault();
    // TODO setTimeout() だとタイミング次第。
    let obj = this;
    window.setTimeout(() => {
      obj.changeValueElement(event);
    }, 200);
  });

  this.dropdownMenuElement.addEventListener("click", (event) => {
    event.preventDefault();
    this.clickDropdownMenu(event);
  });

  this.clearButtonElement.addEventListener("click", (event) => {
    event.preventDefault();
    this.clickClearButton(event);
  });
}

NewObject.prototype.escapeKeydown = function(event) {
  this.setInitValue();
}

NewObject.prototype.arrowUpKeydown = function(event) {
  let active = this.dropdownMenuElement.querySelector(".active");  // a タグ
  if (active) {
    let parent = active.parentElement;  // li タグ
    if (parent.previousElementSibling) {
      active.classList.remove("active");
      parent.previousElementSibling.firstChild.classList.add("active");
      parent.previousElementSibling.scrollIntoViewIfNeeded();
    }
  }
}

NewObject.prototype.arrowDownKeydown = function(event) {
  let active = this.dropdownMenuElement.querySelector(".active");  // a タグ
  if (active) {
    let parent = active.parentElement;  // li タグ
    if (parent.nextElementSibling) {
      active.classList.remove("active");
      parent.nextElementSibling.firstChild.classList.add("active");
      parent.nextElementSibling.scrollIntoViewIfNeeded();
    }
  } else {
    let first = this.dropdownMenuElement.querySelector("a");
    if (first) {
      first.classList.add("active");
      first.parentElement.scrollIntoViewIfNeeded();
    }
  }
}

NewObject.prototype.enterKeydown = function(event) {
  let blurEvent = new CustomEvent("blur");
  this.valueElement.dispatchEvent(blurEvent);
}

NewObject.prototype.changeValueElement = function(event) {
  event.stopImmediatePropagation();

  if (!this.dropdownMenuElement.classList.contains("show")) {
    return;
  }

  var active = this.dropdownMenuElement.querySelector(".active");
  if (active) {
    let item = active.isearchItem;
    this.setValueAndItem(item[this.valueAttr], item);
  } else {
    let menuItems = this.dropdownMenuElement.querySelectorAll(".dropdown-item");
    if (menuItems.length == 1) {
      let item = menuItems[0].isearchItem;
      this.setValueAndItem(item[this.valueAttr], item);
    } else if (menuItems.length == null || menuItems.length > 1) {
      this.setEmptyValue();
    }
  }
  this.hideDropdownMenu()
}

NewObject.prototype.inputValueElement = function(event) {
  let query = this.valueElement.value.trim().replace(/\s+/g, ' ');

  if (0 < query.length) {
    this.executeQuery(query);

  } else if (query.length == 0) {
    this.hideDropdownMenu();
  }
}

NewObject.prototype.clickDropdownMenu = function(event) {
  var active = this.dropdownMenuElement.querySelector(".active");

  if (active && event.target != active) {
    active.classList.remove("active");
  }

  event.target.classList.add("active");
  event.target.scrollIntoViewIfNeeded();
}

NewObject.prototype.clickClearButton = function(event) {
  this.setValueAndItem("");
  this.valueElement.focus();
}

NewObject.prototype.executeQuery = function(query) {
  let obj = this;

  let url = new URL(location.origin);
  url.pathname = obj.apiPath;
  url.searchParams.set("q", query)

  get_json(url.toString(), function(data) {

    let elements = obj.createDropdownItemElements(data);

    remove_child_nodes(obj.dropdownMenuElement);

    if (0 < elements.length) {
      elements.forEach(function(list_item) {
        obj.dropdownMenuElement.appendChild(list_item);
      });

      obj.dropdownMenuElement.classList.add("show");
    }
  });
}

NewObject.prototype.hideDropdownMenu = function() {
  remove_child_nodes(this.dropdownMenuElement);
  this.dropdownMenuElement.classList.remove("show");
}

NewObject.prototype.createDropdownItemElements = function(data) {
  return data.map((item) => {
    let dropdownItem = document.createElement("a");
    dropdownItem.classList.add("dropdown-item");
    dropdownItem.text = item[this.valueAttr] + " " + item[this.textAttr];
    dropdownItem.isearchItem = item;

    let li = document.createElement("li")
    li.appendChild(dropdownItem);
    return li;
  });
}

NewObject.prototype.setInitValue = function() {
  this.valueElement.value = this.initValue;
  this.hideDropdownMenu();
}

NewObject.prototype.setEmptyValue = function() {
  this.valueElement.value = "";
  this.hideDropdownMenu();
}

NewObject.prototype.setValueAndItem = function(value, item) {
  let fireEvent = value != this.valueElement.value;

  this.valueElement.value = value;
  this.initValue = value;

  if (fireEvent) {
    let event = new Event("change");
    this.valueElement.dispatchEvent(event);
  }

  let isearchChangeEvent = new CustomEvent("isearch-change", { detail: item, bubbles: true });
  if (item) {
    isearchChangeEvent.isearchItem = item;
  }
  this.valueElement.dispatchEvent(isearchChangeEvent);
}

function query_parent(source, selector) {
  var target = source.parentElement;
  while (!target.matches(selector)) {
    target = target.parentElement;
  }
  return target;
}

function remove_child_nodes(element) {
  while (element.childNodes.length > 0) {
    element.removeChild(element.firstChild);
  }
}

function get_json(url, f) {
  let req = new XMLHttpRequest();

  req.addEventListener("load", function(e) {
    f(JSON.parse(req.responseText));
  });

  req.open("GET", url);
  req.send();
}
