import * as domutils from './domutils/index';
import * as action from './action/index';
import IncrementalSearchShohinCode from './uriage/shohin-code-incremental-search';
import DeleteUriageMeisai from './uriage/delete-uriage-meisai';
import UpdateGyoNo from './uriage/update-gyo-no';
import ObjectUtils from './lib/ObjectUtils';
import SetKyufubi from './uriage/SetKyufubi';
import SetKyufuNengetsu from './uriage/SetKyufuNengetsu';
const setup = require('./setup');
// import * as action from './action/index';

class UriageEdit {

    uriageEditElement: HTMLElement;                                     // 基準要素

    constructor(element: HTMLElement) {
        this.uriageEditElement = element;

        this.zeiKeisanHohoChainValue();
        this.zeiritsuChainValue();

        this.sendForm("[data-action~=set-seikyusaki]", this.setSeikyusakiBeforeSend, this.setSeikyusakiAfterLoad);
        this.sendForm("[data-action~=create-uriage-meisai]", this.createUriageMeisaiBeforeSend);

        this.uriageEditElement.querySelectorAll<HTMLElement>("[data-action=UpdateGyoNo]").forEach((element) => new UpdateGyoNo(this.uriageEditElement, element));
        this.uriageEditElement.querySelectorAll<HTMLElement>("[data-action=IncrementalSearchShohinCode]").forEach((element) => new IncrementalSearchShohinCode(this.uriageEditElement, element));

        this.sendFormOnBlur("[name=suryo][data-action~=send-form]");
        this.sendFormOnBlur("[name=uriageTanka][data-action~=send-form]");
        this.sendFormOnBlur("[name=genkaTanka][data-action~=send-form]");
        this.sendFormOnBlur("[name=sokoCode][data-action~=send-form]");
        this.sendForm("[name=kazeiKubun][data-action~=send-form]");
        this.sendForm("[name=zeiritsu][data-action~=send-form]");

        this.uriageEditElement.querySelectorAll<HTMLElement>("[data-action=DeleteUriageMeisai]").forEach((element) => new DeleteUriageMeisai(this.uriageEditElement, element));
        SetKyufubi.initComponent(this.uriageEditElement);
        SetKyufuNengetsu.initComponent(this.uriageEditElement);
        this.uriageEditElement.querySelectorAll<HTMLElement>("[data-action~=set-denpyo-biko]").forEach((element) => new SetDenpyoBiko(this, element));

        this.setReload();
    }

    private zeiKeisanHohoChainValue() {
        this.uriageEditElement.querySelectorAll("[name=zeiKeisanHoho][data-action~=chain-value]").forEach(zeiKeisanHoho => {
            zeiKeisanHoho.addEventListener("change", () => {
                this.uriageEditElement.querySelectorAll("[name=kazeiKubun]").forEach(kazeiKubun => {
                    let selectedOption = domutils.getSelectedOption(zeiKeisanHoho);
                    let value = domutils.getDatasetValue(selectedOption, "kazeiKubun");
                    domutils.setValue(kazeiKubun, value);

                    kazeiKubun.dispatchEvent(new Event("change"));
                });
            });
        });
    }

    private zeiritsuChainValue() {
        this.uriageEditElement.querySelectorAll("[name=zeiritsu][data-action=chain-value]").forEach(zeiritsu => {
            zeiritsu.addEventListener("change", () => {
                this.uriageEditElement.querySelectorAll("[name=zeiritsu]").forEach(meisaiZeiritsu => {
                    if (zeiritsu != meisaiZeiritsu) {
                        let value = domutils.getValue(zeiritsu);
                        domutils.setValue(meisaiZeiritsu, value);

                        meisaiZeiritsu.dispatchEvent(new Event("change"));
                    }
                });
            });
        });
    }

    private sendForm(selectors: string, beforeSend?: (formData: FormData, element: Element) => void, afterLoad?: (response: any, element: Element) => void) {
        this.uriageEditElement.querySelectorAll(selectors).forEach(element => {

            let event = domutils.getDatasetValue(element, "event");

            element.addEventListener(event, () => {
                if (!this.confirm(element)) {
                    return;
                }

                let form = domutils.getForm(element);
                let formData = this.prepareFormData(form, element, beforeSend);

                if (domutils.hasDatasetKey(element, "timeout")) {
                    let timeout = domutils.getDatasetValue(element, "timeout");
                    window.setTimeout(() => {
                        this.send(form, formData, element, afterLoad);
                    }, Number(timeout));

                } else {
                    this.send(form, formData, element, afterLoad);
                }
            });

        });
    }

    private sendFormOnBlur(selectors: string) {
        this.uriageEditElement.querySelectorAll(selectors).forEach(element => {
            new action.SendFormOnBlur(this.uriageEditElement, element);
        });
    }

    private confirm(element: Element) {
        if (domutils.hasDatasetKey(element, "confirm")) {
            return window.confirm(domutils.getDatasetValue(element, "confirm"));
        } else {
            return true;
        }
    }

    private prepareFormData(form: HTMLFormElement, element: Element, beforeSend?: (formData: FormData, element: Element) => void): FormData {
        let formData = new FormData(form);

        if (beforeSend != undefined) {
            beforeSend.call(this, formData, element);
        }

        return formData;
    }

    private send(form: HTMLFormElement, formData: FormData, element: Element, afterLoad?: (response: any, element: Element) => void) {
        fetch(form.action, {
            method: form.method,
            body: formData
        })
        .then(response => response.json())
        .then((data) => {
            this.reload(element);

            if (afterLoad) {
                afterLoad.call(this, data, element);
            }
        });
    }

    private reload(element: Element): void {
        if (!(domutils.hasDatasetKey(element, "reload"))) {
            return;
        }

        let reload = domutils.getDatasetValue(element, "reload");

        this.uriageEditElement.querySelectorAll(reload).forEach((target) => {
            target.dispatchEvent(new Event("reload"));
        });
    }


    private createUriageMeisaiBeforeSend(formData: FormData, element: Element): void {
        this.uriageEditElement.querySelectorAll("#zeiKeisanHoho").forEach(zeiKeisanHoho => {
            let selectedOption = domutils.getSelectedOption(zeiKeisanHoho);
            formData.append("kazeiKubun", domutils.getDatasetValue(selectedOption, "kazeiKubun"));
        });

        this.uriageEditElement.querySelectorAll("#zeiritsu").forEach(zeiritsu => {
            formData.append("zeiritsu", domutils.getValue(zeiritsu));
        });
    };

    private setSeikyusakiBeforeSend(formData: FormData, element: Element): void {
        // 入力されている得意先ｺｰﾄﾞを取得する
        let tokuisakiCode = this.findAndGet("#tokuisakiCode");
        // 請求先区分を取得する
        let seikyusakiKubun = domutils.getDatasetValue(element, "src2");

        formData.set("code", tokuisakiCode);
        formData.set("kubun", seikyusakiKubun);
    };

    private setSeikyusakiAfterLoad(data: any, element: Element): void {
        // 請求区分を更新する
        let value = domutils.getDatasetValue(element, "src2");
        this.findAndSet("#seikyusakiKubun", value);
    };

    private setReload(): void {
        this.uriageEditElement.querySelectorAll("[data-action~=reload]").forEach((element) => {
            element.addEventListener("reload", (event) => {
                let reloadUrl = domutils.getDatasetValue(element, "reloadUrl");
                fetch(reloadUrl)
                .then(response => response.text())
                .then((html) => {
                    while (element.firstChild) {
                        element.removeChild(element.firstChild);
                    }

                    element.innerHTML = html;

                    // TODO コールバックにしたい
                    if (element.id == "uriageMeisaiFormItem") {
                        setup.sokoCodeIsearch();
                        setup.times();

                        this.sendForm("[data-action~=create-uriage-meisai]", this.createUriageMeisaiBeforeSend);
                        element.querySelectorAll<HTMLElement>("[data-action=UpdateGyoNo]").forEach((element) => new UpdateGyoNo(this.uriageEditElement, element));
                        element.querySelectorAll<HTMLElement>("[data-action=IncrementalSearchShohinCode]").forEach((element) => new IncrementalSearchShohinCode(this.uriageEditElement, element));

                        this.sendFormOnBlur("[name=suryo][data-action~=send-form]");
                        this.sendFormOnBlur("[name=uriageTanka][data-action~=send-form]");
                        this.sendFormOnBlur("[name=genkaTanka][data-action~=send-form]");
                        this.sendFormOnBlur("[name=sokoCode][data-action~=send-form]");
                        this.sendForm("[name=kazeiKubun][data-action~=send-form]");
                        this.sendForm("[name=zeiritsu][data-action~=send-form]");

                        element.querySelectorAll<HTMLElement>("[data-action=DeleteUriageMeisai]").forEach((element) => new DeleteUriageMeisai(this.uriageEditElement, element));
                    }
                });
            });
        });
    }

    findAndGet(selectors: string): string {
        let element = this.uriageEditElement.querySelector(selectors);
        if (element == null) {
            throw new Error("element not found." + selectors);
        }
        return domutils.getValue(element);
    }

    findAndSet(selectors: string, value: any, event?: string): void {
        let element = this.uriageEditElement.querySelector(selectors);
        if (element == null) {
            throw new Error("element not found." + selectors);
        }
        domutils.setValue(element, value, event);
    }
}



class SetDenpyoBiko {
    context: UriageEdit
    element: Element;

    hakkobiElement: Element;
    kyufuNengetsuElement: Element;
    bumonCodeElement: Element;
    tokuisakiCodeElement: Element;

    constructor(context: UriageEdit, element: Element) {
        this.context = context;
        this.element = element;

        this.hakkobiElement = domutils.requireObject(this.context.uriageEditElement.querySelector("#hakkobi"));
        this.kyufuNengetsuElement = domutils.requireObject(this.context.uriageEditElement.querySelector("#kyufuNengetsu"));
        this.bumonCodeElement = domutils.requireObject(this.context.uriageEditElement.querySelector("#bumonCode"));
        this.tokuisakiCodeElement = domutils.requireObject(this.context.uriageEditElement.querySelector("#tokuisakiCode"));

        this.tokuisakiCodeElement.addEventListener("change", event => this.handleChange(event));
        this.kyufuNengetsuElement.addEventListener("change", event => this.handleChange(event));

        this.element.addEventListener("click", event => this.handleClick(event));

        this.formatDenpyoBiko();
    }

    private handleClick(event: Event): any {
        let value = domutils.getValue(this.element);
        if (value == "クリア") {
            value = "";
        }
        this.context.findAndSet("#denpyoBiko", value);
    }

    private handleChange(event: Event): any {
        this.formatDenpyoBiko();
    }

    private formatDenpyoBiko() {
        let motoDenpyoNo = this.context.findAndGet("#motoDenpyoNo");
        if (0 < motoDenpyoNo.trim().length) {
            return;
        }

        let hakkobi = domutils.getValue(this.hakkobiElement);
        let kyufuNengetsu = domutils.getValue(this.kyufuNengetsuElement);
        let bumonCode = domutils.getValue(this.bumonCodeElement);
        let tokuisakiCode = domutils.getValue(this.tokuisakiCodeElement);

        if (hakkobi.trim().length == 0 || kyufuNengetsu.trim().length == 0 || tokuisakiCode.trim().length == 0) {
            this.context.findAndSet("[data-action~=set-denpyo-biko]", "クリア");
            return;
        }

        if (bumonCode.trim().length == 0 || bumonCode != "4") {
            this.context.findAndSet("[data-action~=set-denpyo-biko]", "クリア");
            return;
        }

        let denpyoBikoUrl = domutils.getDatasetValue(this.element, "denpyoBikoUrl");

        let params = new URLSearchParams();
        params.append("tokuisakiCode", tokuisakiCode);
        params.append("nengetsu", kyufuNengetsu);

        fetch(denpyoBikoUrl + "?" + params.toString())
        .then(response => response.json())
        .then((json) => {
            this.context.findAndSet("[data-action~=set-denpyo-biko]", json.denpyoBiko);
            let jikoritsuElement = ObjectUtils.require(this.context.uriageEditElement.querySelector("#jikoritsu"), HTMLInputElement);
            let gendogakuElement = ObjectUtils.require(this.context.uriageEditElement.querySelector("#gendogaku"), HTMLInputElement);
            jikoritsuElement.placeholder = json.jikoritsu ?? 'jikoritsu';
            gendogakuElement.placeholder = json.gendogaku ?? 'gendogaku';
        });
    }

}

export default UriageEdit;

window.addEventListener('DOMContentLoaded', (event) => {
    document.querySelectorAll<HTMLElement>("#uriageNew,#uriageEdit").forEach((element) => {
        new UriageEdit(element);
    })
});
