import * as domutils from '../domutils/index';

export class SetHaitatsubi {

    element: Element;

    constructor(element: Element) {
        this.element = element;
        element.addEventListener("click", (event) => this.handleClick(event));
    }

    private dataHaitatsubi(): string {
        return domutils.getDatasetValue(this.element, "haitatsubi");
    }

    private dataText(): string {
        return domutils.getDatasetValue(this.element, "text");
    }

    private handleClick(event: Event): any {
        this.haitatsubiElement().value = this.dataText();
    }

    private haitatsubiElement(): HTMLInputElement {
        return domutils.findFirst(document, this.dataHaitatsubi());
    }


}
