import * as domutils from '../domutils/index';
import ConfirmDialog from './confirm-dialog'

const bootstrap = require('bootstrap');

export class ConfirmDialogButton {
    element: Element;

    constructor(element: Element) {
        this.element = element;
        element.addEventListener("click", (event) => this.handleClick(event));
    }

    private dataEsConfirmDialog() {
        return domutils.getDatasetValue(this.element, "esConfirmDialog");
    }

    private handleClick(event: Event) {
        // submit をキャンセル
        event.preventDefault();

        let confirmDialogElement = document.querySelector("#confirmDialog");
        if (confirmDialogElement === null) {
            throw new Error("element not confirmDialog.");
        }
        // 確認ダイアログセットアップの呼び出し。
        let confirmDialog = new ConfirmDialog(confirmDialogElement, this.dataEsConfirmDialog(), () => this.submit());

        confirmDialog.show();
    }

    private submit() {
        domutils.getForm(this.element).submit();
    }
}

export default ConfirmDialogButton;