import StringUtils from "../lib/StringUtils";
import PostButtonElement from "./PostButtonElement";
import MitsumorigakuElement from "./MitsumorigakuElement";
import ShohinCodeElement from "./ShohinCodeElement";
import MitsumorishoMeisaiElement from "./MitsumorishoMeisaiElement";

class MitsumorishoMeisaiIndex {

    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=MitsumorishoMeisaiIndex]").forEach((element) => {
            new MitsumorishoMeisaiIndex(element, parentElement);
        });
    }

    private mitsumorishoMeisaiIndexElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(mitsumorishoMeisaiIndexElement: HTMLElement, parentElement: HTMLElement) {

        this.mitsumorishoMeisaiIndexElement = mitsumorishoMeisaiIndexElement;
        this.parentElement = parentElement;

        this.init();

        this.mitsumorishoMeisaiIndexElement.addEventListener("reload", event => this.handleReload(event));
    }

    private async handleReload(event: Event) {

        let reloadPath = StringUtils.require(this.mitsumorishoMeisaiIndexElement.dataset["reloadPath"]);
        let url = new URL(location.origin + reloadPath);

        let response = await fetch(url);
        this.mitsumorishoMeisaiIndexElement.innerHTML = await response.text();

        this.init();
    }

    private init() {
        PostButtonElement.initComponent(this.parentElement);
        MitsumorishoMeisaiElement.initComponent(this.parentElement);
    }
}

export default MitsumorishoMeisaiIndex;
