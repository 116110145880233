import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";
import ISearch from "./ISearch";

class ShohinCodeElement {

    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=ShohinCodeElement]").forEach((element) => {
            new ShohinCodeElement(element, parentElement);
        });
    }

    private shohinCodeElement: HTMLInputElement;
    private parentElement: HTMLElement;
    private tankaElement: HTMLInputElement;
    private mitsumorigakuElement: HTMLInputElement;

    private constructor(shohinCodeElement: HTMLInputElement, parentElement: HTMLElement) {
        this.shohinCodeElement = shohinCodeElement;
        this.parentElement = parentElement;

        // url を設定する
        let apiPath = StringUtils.require(this.shohinCodeElement.dataset["apiPath"])
        let url = new URL(location.origin + apiPath);

        let kazeiKubunElement = ObjectUtils.require(document.querySelector("#kazeiKubun"), HTMLSelectElement);
        url.searchParams.append("kazeiKubun", kazeiKubunElement.value);

        let zeiritsuElement = ObjectUtils.require(document.querySelector("#zeiritsu"), HTMLSelectElement);
        url.searchParams.append("zeiritsu", zeiritsuElement.value);

        let shohinMeiElement = ObjectUtils.require(this.parentElement.querySelector("[data-name=shohinMei]"), HTMLInputElement);
        this.tankaElement = ObjectUtils.require(this.parentElement.querySelector("[data-name=mitsumoriTanka]"), HTMLInputElement);
        this.mitsumorigakuElement = ObjectUtils.require(this.parentElement.querySelector("[data-name=mitsumorigaku]"), HTMLInputElement);

        new ISearch(this.shohinCodeElement, url, "shohinCode", "shohinMei", shohinMeiElement);

        this.shohinCodeElement.addEventListener("isearch-change", event => this.handleISearchChange(event));
    }

    private async handleISearchChange(event: Event) {
        if (!(event instanceof CustomEvent)) {
            return;
        }

        if (event.detail === null) {
            this.tankaElement.value = "";
        } else {
            this.tankaElement.value = event.detail.uriageTanka;
        }

        // CSRF トークンを取得する
        let csrfElement = ObjectUtils.require(document.querySelector("[name=_csrf]"), HTMLInputElement);
        let shohinMeiElement = ObjectUtils.require(this.parentElement.querySelector("[data-name=shohinMei]"), HTMLInputElement);

        // FormData を作成する
        let formData = new FormData();
        formData.append("_csrf", csrfElement.value);                // CSRF トークン
        formData.append("shohinCode", this.shohinCodeElement.value);      // 項目名と値
        formData.append("shohinMei", shohinMeiElement.value);

        // パスを取得し POST する
        let postPath = StringUtils.require(this.parentElement.dataset["postPath"]);
        let url = new URL(location.origin + postPath);

        let response = await fetch(url, { method: "post", body: formData });
        let responseJson = await response.json();

        // OK のときはエラー表示解除
        if (responseJson.status === "OK") {
            this.shohinCodeElement.classList.remove("is-invalid");

        // OK でないときはエラー表示。
        } else {
            this.shohinCodeElement.classList.add("is-invalid");
        }

        this.mitsumorigakuElement.dispatchEvent(new Event("times"));
    }
}

export default ShohinCodeElement;