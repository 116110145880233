export function findAllElement(element, selector, callback) {
    let elements = [];
    for (let e of element.querySelectorAll(selector).values()) {
        if (callback !== undefined) {
            callback(e);
        }
        elements.push(e);
    }
    return elements;
}

export function findFirstElement(element, selector, callback) {
    for (let e of element.querySelectorAll(selector).values()) {
        if (callback !== undefined) {
            callback(e);
        }
        return e;
    }
    return null;
}
