import ConfirmDialog from "../action/ConfirmDialog";
import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";

class PostButtonElement {

    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=PostButtonElement]").forEach((element) => {
            new PostButtonElement(element, parentElement);
        });
    }

    private postButtonElement: HTMLInputElement;
    private parentElement: HTMLElement;

    private constructor(postButtonElement: HTMLInputElement, parentElement: HTMLElement) {
        this.postButtonElement = postButtonElement;
        this.parentElement = parentElement;

        this.postButtonElement.addEventListener("click", event => this.handleClick(event));
    }

    private async handleClick(event: Event) {
        event.preventDefault();

        let confirmMessage = this.postButtonElement.dataset["confirmMessage"];
        if (confirmMessage) {
            ConfirmDialog.getInstance().show(confirmMessage, (event) => this.post());
        } else {
            this.post();
        }
    }

    private async post() {
        let csrfElement = ObjectUtils.require(this.parentElement.querySelector("[name=_csrf]"), HTMLInputElement);

        let postPath = StringUtils.require(this.postButtonElement.dataset["postPath"]);
        let url = new URL(location.origin + postPath);

        let formData = new FormData();
        formData.append("_csrf", csrfElement.value);

        let response = await fetch(url, { method: "post", body: formData });

        let responseJson = await response.json();
        if (responseJson.status === "OK") {

            document.querySelectorAll<HTMLElement>("#mitsumorishoMeisaiIndex,#mitsumorigakuTo").forEach((reloadTargetElement) => {
                let event = new CustomEvent("reload");
                reloadTargetElement.dispatchEvent(event);
            })
        }
    }
}

export default PostButtonElement;
