import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";
import MitsumorigakuElement from "./MitsumorigakuElement";
import ShohinCodeElement from "./ShohinCodeElement";

/**
 * 見積明細更新コンポーネント
 *
 * 見積明細の各項目を入力したときにサーバー側を更新する
 */
class MitsumorishoMeisaiElement {

    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=MitsumorishoMeisaiElement]").forEach((element) => {
            new MitsumorishoMeisaiElement(element, parentElement);
        });
    }

    private mitsumorishoMeisaiElement: HTMLInputElement;
    private parentElement: HTMLElement;

    /**
     * コンストラクタ
     */
    private constructor(mitsumorishoMeisaiElement: HTMLInputElement, parentElement: HTMLElement) {
        this.mitsumorishoMeisaiElement = mitsumorishoMeisaiElement;
        this.parentElement = parentElement;

        ShohinCodeElement.initComponent(this.mitsumorishoMeisaiElement);
        MitsumorigakuElement.initComponent(this.mitsumorishoMeisaiElement);

        // 含まれている入力項目に change イベントを設定する。
        this.mitsumorishoMeisaiElement.querySelectorAll("input").forEach(element => {
            element.addEventListener("change", event => this.handleChange(event));
        });
    }

    /**
     * change イベントハンドラ
     */
    private async handleChange(event: Event) {

        // 入力された項目とその name を取得する。
        let inputElement = ObjectUtils.require(event.target, HTMLInputElement);
        let inputElementName = StringUtils.require(inputElement.dataset["name"]);

        // 数量と見積り単価のときは見積額コンポーネントに times イベントを発生し、処理を委譲
        if (inputElementName === "suryo" || inputElementName === "mitsumoriTanka") {
            let kingakuElement = ObjectUtils.require(this.mitsumorishoMeisaiElement.querySelector("[data-name=mitsumorigaku]"), HTMLInputElement);
            kingakuElement.dispatchEvent(new CustomEvent("times"));

            return;
        }

        // 商品コードは isearch-change イベントで送信するため無視。
        if (inputElementName === "shohinCode") {
            return;
        }

        // CSRF トークンを取得する
        let csrfElement = ObjectUtils.require(this.parentElement.querySelector("[name=_csrf]"), HTMLInputElement);

        // FormData を作成する
        let formData = new FormData();
        formData.append("_csrf", csrfElement.value);                // CSRF トークン
        formData.append(inputElementName, inputElement.value);      // 項目名と値

        // パスを取得し POST する
        let postPath = StringUtils.require(this.mitsumorishoMeisaiElement.dataset["postPath"]);
        let url = new URL(location.origin + postPath);

        let response = await fetch(url, { method: "post", body: formData });
        let responseJson = await response.json();

        // OK のときはエラー表示解除
        if (responseJson.status === "OK") {
            inputElement.classList.remove("is-invalid");

            // 行No.を送ったときは見積額等をリロードする。
            if (inputElementName === "gyoNo") {
                let reloadTargetElement = ObjectUtils.require(document.querySelector("#mitsumorishoMeisaiIndex"), HTMLElement);
                reloadTargetElement.dispatchEvent(new CustomEvent("reload"));
            }

        // OK でないときはエラー表示。
        } else {
            inputElement.classList.add("is-invalid");
        }
    }
}

export default MitsumorishoMeisaiElement;
