import KazeiKubunAndZeiritsuElement from "./KazeiKubunAndZeiritsuElement";
import MitsumorigakuTo from "./MitsumorigakuTo";
import MitsumorishoMeisaiIndex from "./MitsumorishoMeisaiIndex";

class MitsumorishoEdit {

    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=MitsumorishoEdit]").forEach((element) => {
            new MitsumorishoEdit(element);
        });
    }

    private mitsumorishoEditElement: HTMLElement;

    private constructor(mitsumorishoEditElement: HTMLElement) {
        this.mitsumorishoEditElement = mitsumorishoEditElement;

        KazeiKubunAndZeiritsuElement.initComponent(this.mitsumorishoEditElement);
        MitsumorishoMeisaiIndex.initComponent(this.mitsumorishoEditElement);
        MitsumorigakuTo.initComponent(this.mitsumorishoEditElement);
    }
}

export default MitsumorishoEdit;
