class KojinmukeShimeSeikyushoIndexHikiotoshibi {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=KojinmukeShimeSeikyushoIndexHikiotoshibi]").forEach((element) => {
            new KojinmukeShimeSeikyushoIndexHikiotoshibi(element, parentElement);
        });
    }

    private kojinmukeShimeSeikyushoIndexHikiotoshibiElement: HTMLInputElement;
    private parentElement: HTMLElement

    private constructor(kojinmukeShimeSeikyushoIndexHikiotoshibiElement: HTMLInputElement, parentElement: HTMLElement) {
        this.kojinmukeShimeSeikyushoIndexHikiotoshibiElement = kojinmukeShimeSeikyushoIndexHikiotoshibiElement;
        this.parentElement = parentElement;

        this.kojinmukeShimeSeikyushoIndexHikiotoshibiElement.addEventListener("change", (event) => this.handleChange(event));
    }

    private handleChange(event: Event) {
        let hikiotoshibi = this.kojinmukeShimeSeikyushoIndexHikiotoshibiElement.value;

        this.parentElement.querySelectorAll<HTMLInputElement>("[name=hikiotoshibi]").forEach((element) => {
            if (this.kojinmukeShimeSeikyushoIndexHikiotoshibiElement !== element) {
                element.value = this.kojinmukeShimeSeikyushoIndexHikiotoshibiElement.value;
            }
        });
    }
}

export default KojinmukeShimeSeikyushoIndexHikiotoshibi;
