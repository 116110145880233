import * as domutils from '../domutils/index';

class UpdateGyoNo {
    parentElement: HTMLElement;
    gyoNoElement: HTMLElement;

    uriageMeisaiFormListElement: HTMLElement;
    uriageMeisaiFormItemElement: HTMLElement;

    private changed: boolean = false;

    constructor(parentElement: HTMLElement, gyoNoElement: HTMLElement) {
        this.parentElement = parentElement;
        this.gyoNoElement = gyoNoElement;

        this.uriageMeisaiFormListElement = domutils.requireObject(document.querySelector<HTMLElement>("#uriageMeisaiFormList"));
        this.uriageMeisaiFormItemElement = domutils.requireObject(document.querySelector<HTMLElement>("#uriageMeisaiFormItem"));

        this.gyoNoElement.addEventListener("focus", (event: Event) => this.changed = false);
        this.gyoNoElement.addEventListener("change", (event: Event) => this.changed = true);

        this.gyoNoElement.addEventListener("blur", (ev) => this.handleBlur(ev));
    }

    handleBlur(ev: FocusEvent) {
        if (this.changed) {
            let form = domutils.getForm(this.gyoNoElement);
            let formData = new FormData(form);

            fetch(form.action, {
                method: form.method,
                body: formData
            })
            .then(response => response.json())
            .then(() => this.reload());
        }
    }

    reload() {
        this.uriageMeisaiFormListElement.dispatchEvent(new CustomEvent("reload"));
        this.uriageMeisaiFormItemElement.dispatchEvent(new CustomEvent("reload"));
    }
}

export default UpdateGyoNo;
