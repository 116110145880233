export default class DateUtils {

    static now(): Date {
        return new Date();
        // let d = new Date();
        // d.setDate(9);
        // return d;
    }

    static newInstance(year: number, monthIndex: number, date?: number | undefined): Date {
        return new Date(year, monthIndex, date);
    }

    static parse(s: string): Error|Date {

        let d = new Date(s);
        if (this.isInvalidDate(d)) {
            return new Error();
        }

        d.setMinutes(d.getTimezoneOffset());
        return d;
    }

    static isInvalidDate(d: Date): boolean {
        return Number.isNaN(d.getTime());
    }

    static toISODateString(d: Date): string {
        return d.toISOString().substring(0, 7);
    }
}