import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";
import ConfirmDialog from "./ConfirmDialog";

export default class ConfirmSubmitButton {

    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=ConfirmSubmitButton]").forEach((element) => {
            new ConfirmSubmitButton(element, parentElement);
        });
    }

    private confirmSubmitButtonElement: HTMLInputElement;
    private parentElement: HTMLElement;

    private constructor(confirmSubmitButtonElement: HTMLInputElement, parentElement: HTMLElement) {
        this.confirmSubmitButtonElement = confirmSubmitButtonElement;
        this.parentElement = parentElement;

        this.confirmSubmitButtonElement.addEventListener("click", event => this.handleClick(event));
    }

    private handleClick(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        let message = ObjectUtils.requireString(this.confirmSubmitButtonElement.dataset["confirmMessage"]);
        ConfirmDialog.getInstance().show(message, event => this.handleComfirmed(event));
    }

    private handleComfirmed(event: Event) {
        let formElement = ObjectUtils.require(this.confirmSubmitButtonElement.form, HTMLFormElement);
        let formMethod = formElement.method;
        let formAction = formElement.action;

        try {
            if (StringUtils.isNotBlank(this.confirmSubmitButtonElement.formMethod)) {
                formElement.method = this.confirmSubmitButtonElement.formMethod;
            }

            if (StringUtils.isNotBlank(this.confirmSubmitButtonElement.formAction)) {
                formElement.action = this.confirmSubmitButtonElement.formAction;
            }

            formElement.submit();
        } finally {
            formElement.method = formMethod;
            formElement.action = formAction;
        }
    }
}
