import * as domutils from '../domutils/index';

export class SendFormOnBlur {

    private contentsElement: Element;
    private element: Element;                                     // 基準要素
    private changed: boolean = false;

    constructor(contentsElement: Element, element: Element, beforeSend?: (formData: FormData, element: Element) => void, afterLoad?: (response: any, element: Element) => void) {
        this.contentsElement = contentsElement;
        this.element = element;

        this.element.addEventListener("focus", (event: Event) => {
            this.changed = false;
        });

        this.element.addEventListener("change", (event: Event) => {
            this.changed = true;
        });

        this.element.addEventListener("blur", (event: Event) => {
            if (this.changed) {
                let form = domutils.getForm(element);
                let formData = this.prepareFormData(form, element, beforeSend);

                if (domutils.hasDatasetKey(element, "timeout")) {
                    let timeout = domutils.getDatasetValue(element, "timeout");
                    window.setTimeout(() => {
                        this.send(form, formData, element, afterLoad);
                    }, Number(timeout));

                } else {
                    this.send(form, formData, element, afterLoad);
                }
            }
        });
    }

    private prepareFormData(form: HTMLFormElement, element: Element, beforeSend?: (formData: FormData, element: Element) => void): FormData {
        let formData = new FormData(form);

        if (beforeSend != undefined) {
            beforeSend.call(this, formData, element);
        }

        return formData;
    }

    private send(form: HTMLFormElement, formData: FormData, element: Element, afterLoad?: (response: any, element: Element) => void) {
        fetch(form.action, {
            method: form.method,
            body: formData
        })
        .then(response => response.json())
        .then((data) => {
            this.reload(element);

            if (afterLoad) {
                afterLoad.call(this, data, element);
            }
        });
    }

    private reload(element: Element): void {
        if (!(domutils.hasDatasetKey(element, "reload"))) {
            return;
        }

        let reload = domutils.getDatasetValue(element, "reload");

        this.contentsElement.querySelectorAll(reload).forEach((target) => {
            target.dispatchEvent(new Event("reload"));
        });
    }

}