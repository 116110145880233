class NumberUtils {

    static parse(s: string | undefined): number {
        if (s === undefined) {
            return 0;
        }

        return Number(s);
    }

    static toString(n: number) {
        return n.toString();
    }

    static currency(n: number) {
        return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(n);
    }
}

export default NumberUtils;