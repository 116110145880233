export function setupEasyInputDate(selectors = "input[type=date]") {
    document.querySelectorAll<HTMLInputElement>(selectors).forEach(element => {
        new EasyInputDate(element);
    });
}

const delimiterPattern = /^((?<year>\d{1,4})\D|)(?<month>\d{1,2})\D+(?<day>\d{1,2})$/;
const fixedLengthPattern = /^(?<year>((\d{2}|)\d{2})|)(?<month>\d{2})(?<day>\d{2})$/;

class EasyInputDate {

    private dateElement: HTMLInputElement;
    private recognizeErr: Error|null = null;

    constructor(element: HTMLInputElement) {
        this.dateElement = element;

        if (this.dateElement.readOnly) {
            return;
        }

        this.dateElement.type = "text";
        this.dateElement.addEventListener("blur", (event) => this.handleBlur(event));
        this.dateElement.addEventListener("change", (event) => this.handleChange(event));
    }

    private handleBlur(event: FocusEvent) {
        if (this.recognizeErr instanceof Error) {
            this.dateElement.value = "";

            let event = new Event("change");
            this.dateElement.dispatchEvent(event);
            return;
        }
    }

    private handleChange(event: Event) {
        this.recognizeErr = this.recognize()
    }

    private recognize(): Error|null {
        let value = this.dateElement.value;

        let execArray = this.patternExec(value);
        if (execArray === null) {
            return new Error();
        }

        let year = execArray.groups?.year;
        if (year === undefined) {
            year = "";
        } else if (0 < year.length) {
            year = year.padStart(2, "0");
        }

        let thisYear = new Date().getFullYear().toString();
        year = thisYear.substring(0, 4 - year.length) + year;

        let month = execArray.groups?.month;
        let day = execArray.groups?.day;

        if (month === undefined || day === undefined) {
            return new Error();
        }

        month = month.padStart(2, "0");
        day = day.padStart(2, "0");

        try {
            let d = new Date(year + "-" + month + "-" + day).toISOString().substring(0, 10);

            if (d !== this.dateElement.value) {
                this.dateElement.value = d;
            }
        } catch (error) {
            return new Error();
        }

        return null;
   }

    private patternExec(value: string): RegExpExecArray | null {
        return delimiterPattern.exec(value) || fixedLengthPattern.exec(value);
    }

}

export default EasyInputDate;
