import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";
import UriageEdit from "../uriage-edit";

class SetKyufubi {

    static initComponent(parentElement: HTMLElement) {
        document.querySelectorAll<HTMLElement>("[data-action~=SetKyufubi]").forEach((element) => {
            new SetKyufubi(parentElement, element);
        });
    }

    private parentElement: HTMLElement;
    private element: Element;

    private constructor(parentElement: HTMLElement, element: HTMLElement) {
        this.parentElement = parentElement;
        this.element = element;

        this.element.addEventListener("change", (event) => this.handleChange(event));
    }

    private handleChange(event: Event) {
        let bumonCodeElement = ObjectUtils.require(this.parentElement.querySelector("#bumonCode"), HTMLInputElement);
        let hakkobiElement = ObjectUtils.require(this.parentElement.querySelector("#hakkobi"), HTMLInputElement);
        let kyufubiElement = ObjectUtils.require(this.parentElement.querySelector("#kyufubi"), HTMLInputElement);

        let hakkobi = hakkobiElement.value;
        let bumonCode = bumonCodeElement.value;

        if (bumonCode === "4" && StringUtils.isNotBlank(hakkobi)) {
            kyufubiElement.value = hakkobi;
        } else {
            kyufubiElement.value = "";
        }

        kyufubiElement.dispatchEvent(new CustomEvent("change"));
    }
}

export default SetKyufubi;